<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <!-- 上半部分,账户余额 -->
    <div class="box_top">
      <div class="top1">
        <div class="top1_left">
          <div>账户余额</div>
          <div class="top1_prc">¥{{ balance || 0 }}</div>
        </div>
        <van-button class="top1_btn" @click="showPopup" v-if="balance > 0">申请提现</van-button>
        <van-button class="top1_btn" v-else>暂无可提现余额</van-button>
      </div>
      <!-- 提现记录标题 -->
      <div class="top1_title">提现记录</div>
    </div>
    <!-- 提现记录表头 -->
    <div class="list_title">
      <div>提现类型</div>
      <div>提现金额</div>
      <div>提现账户</div>
      <div>提现状态</div>
      <div>提现时间</div>
    </div>
    <!-- 提现记录表 -->
    <div class="box_index">
      <div
        class="list_index"
        v-for="(item, index) in WalletLoglist"
        :key="index"
      >
        <div>{{ item.t_name }}</div>
        <div>¥{{ item.amount }}</div>
        <div>{{ item.account }}</div>
        <div>{{ item.status | statusName }}</div>
        <div>{{ item.create_time }}</div>
      </div>
    </div>
    <van-col span="12">
      <div style="width: fit-content; margin-left: 8rem; margin-top: 2rem">
        <van-pagination
          v-model="page"
          :total-items="total"
          :items-per-page="limit"
          @change="pageChange"
          force-ellipses
        />
      </div>
    </van-col>
    <van-popup v-model="show">
      <div class="cation_box">
        <div class="cation_title">
          <div>申请提现</div>
          <img
            src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png"
            @click="closePopup"
          />
        </div>
        <div class="box_input">
          <div class="input_left">提现金额：</div>
          <input
            type="number"
            :placeholder="withdrawalShow.price"
            readonly="readonly"
            class="input_index readonly"
          />
        </div>
        <div class="box_input">
          <div class="input_left">提现户名：</div>
          <input
            type="number"
            :placeholder="withdrawalShow.account_name"
            class="input_index readonly"
            readonly="readonly"
          />
        </div>
        <div class="box_input">
          <div class="input_left">提现账户：</div>
          <input
            type="number"
            :placeholder="withdrawalShow.account"
            class="input_index readonly"
            readonly="readonly"
          />
        </div>
        <div class="box_input">
          <div class="input_left">开户行：</div>
          <input
            type="number"
            :placeholder="withdrawalShow.bank"
            class="input_index readonly"
            readonly="readonly"
          />
        </div>
        <div class="cation_btn_box">
          <van-button class="btn cation_btn" @click="submit()">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
export default {
  created() {
    this.name = this.$route.query.name; //拿到传过来的标题
    console.log("name:", this.name);
    this.getInfo();
    this.getWalletLog();
  },
  updated() {},
  components: {
    titlebar: titlebar,
  },
  filters: {
    statusName(val) {
      let obj = {
        1: "等待审核",
        2: "审核通过",
        3: "提现驳回",
      };
      return obj[val] || "";
    },
  },
  data() {
    return {
      show: false, //控制弹窗变量
      balance: null, //可用余额
      WalletLoglist: [], //提现记录
      withdrawalShow: [], //财务管理 申请提现显示
      account: "", //提现账户号
      bank: "", //开户行
      page: 1, //页数
      limit: 8, //条数
      total: 0,
    };
  },
  methods: {
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("销售商品列表，当前页码：", this.page);
      this.getWalletLog();
    },
    // 申请提现弹窗
    showPopup() {
      this.show = true;
      this.getWithdrawalShow();
    },
    // 关闭弹窗
    closePopup() {
      this.show = false;
    },
    // 获取账户余额
    getInfo() {
      var data = { shop_id: localStorage.getItem("shop_id") };
      this.$api.info(data).then((res) => {
        this.balance = res.data.list.balance;
      });
    },
    // 获取提现记录
    getWalletLog() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        t: 1,
        page: this.page,
        limit: this.limit,
      };
      this.$api.walletLog(data).then((res) => {
        this.WalletLoglist = res.data.list;
        this.total = res.data.count;
      });
    },
    // 申请提现显示
    getWithdrawalShow() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        // t: 1,
      };
      this.$api.withdrawalShow(data).then((res) => {
        this.withdrawalShow = res.data.list;
      });
    },
    // 申请提现操作
    submit() {
      var query = {
        shop_id: localStorage.getItem("shop_id"),
        price: this.withdrawalShow.price,
      };
      this.$api.withdrawalAction(query).then((res) => {});
      this.getWalletLog();
      this.getInfo();
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-pagination__item--active {
  color: #fff !important;
  background-color: #1588F5 !important;
}
/deep/.van-pagination__item {
  color: #1588F5;
}
.page {
  width: 192rem;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;

  .cation_box {
    position: relative;
    width: 102.9rem;
    height: 82.7rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-size: 3.6rem;
    font-weight: bold;
    color: #333333;
    .box_input {
      display: flex;
      font-size: 2.8rem;
      font-weight: bold;
      color: #333333;
      align-items: center;
      margin-bottom: 3rem;
      .input_index {
        width: 46.9rem;
        height: 7.1rem;
        border-radius: 0.8rem;
        border: 0.1rem solid #c9c9c9;
        padding-left: 3rem;
      }
    }
    .readonly {
      background-color: #f8f8f8;
    }
    .input_left {
      width: 15.6rem;
    }
    .cation_title {
      height: 12.3rem;
      width: 82.7rem;
      border-bottom: 0.1rem solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 7.9rem;
      img {
        width: 4.3rem;
        height: 4.3rem;
        margin-left: 34rem;
      }
    }
    .cation_input {
      margin-top: 8.1rem;
      width: 84.9rem;
      height: 10.1rem;
      padding-left: 2rem;
      background: #f8f8f8;
      border-radius: 0.8rem;
      font-size: 3rem;
      font-weight: 400;
      color: #666666;
    }
    .cation_btn_box {
      margin-top: 11rem;
      position: absolute;
      bottom: 10rem;
      .btn {
        width: 27rem;
        height: 7rem;
        font-size: 2.8rem;
        font-weight: 500;
        background: linear-gradient(270deg, #1588F5 0%, #1588F5 100%);
        color: #ffffff;
        border: none;
      }
    }
  }
  .pop_box {
    width: 102.9rem;
    height: 82.7rem;
    background: #ffffff;
  }
  .box_top {
    height: 37rem;
    padding: 2.6rem 4.1rem 0rem 4.1rem;
    text-align: left;
    .top1 {
      width: 184.7rem;
      height: 20.8rem;
      background: #ffffff;
      box-shadow: 0rem 0.1rem 1.5rem 0rem rgba(232, 232, 232, 0.5);
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      .top1_left {
        margin-left: 9.6rem;
        margin-right: 34.5rem;
        font-size: 2.8rem;
        font-weight: 500;
        color: #666666;
      }
      .top1_prc {
        font-size: 3.6rem;
        font-weight: 600;
        color: #000000;
        margin-top: 2.2rem;
      }
      .top1_btn {
        width: 25rem;
        height: 7rem;
        border-radius: 0.8rem;
        border: 0.1rem solid #1588F5;
        font-size: 2.8rem;
        font-weight: 500;
        color: #1588F5;
      }
    }
    .top1_title {
      font-size: 3rem;
      font-weight: bold;
      color: #000000;
      margin-top: 7.1rem;
    }
  }
  .list_title {
    height: 8rem;
    background: #f8f8f8;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: center;
  }
  .box_index {
    // height: 40rem;
    flex: 1;
    overflow-y: scroll;
  }
  .box_index::-webkit-scrollbar {
    display: none;
  }
  .list_index {
    height: 7rem;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: center;
    border-bottom: 0.1rem solid #e5e5e5;
  }
}
</style>
